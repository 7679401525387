@import url(https://fonts.googleapis.com/css?family=Avenir:100,400,900,bold&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#top-logo {
  position: absolute;
  width: 290px;
  height: 70px;
  left: 39px;
  top: 30px;
}

#features {
  position: absolute;
  width: 116px;
  height: 27px;
  left: 523px;
  top: 50px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(96, 95, 95, 0.74);
}

#pricing {
  position: absolute;
  width: 116px;
  height: 27px;
  left: 683px;
  top: 50px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(96, 95, 95, 0.74);
}

#customers {
  position: absolute;
  width: 171px;
  height: 27px;
  left: 821px;
  top: 50px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(96, 95, 95, 0.74);
}

#blog {
  position: absolute;
  width: 116px;
  height: 27px;
  left: 1004px;
  top: 50px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(96, 95, 95, 0.74);
}

#forum {
  position: absolute;
  width: 116px;
  height: 27px;
  left: 1120px;
  top: 50px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: rgba(96, 95, 95, 0.74);
}

#sign {
  position: absolute;
  width: 148px;
  height: 60px;
  left: 1254px;
  top: 33px;

  background: #564BD3;
  border-radius: 14px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  
  text-align: center;
  display: inline-block;

  color: #ffffff;
}

#call-out1 {
  position: absolute;
  width: wrap;
  height: wrap;
  left: 68px;
  top: 300px;
  color: #564BD3;
}

#call-out2 {
  position: absolute;
  width: 306px;
  height: 31px;
  left: 70px;
  top: 386px;
  color: rgba(86, 75, 211, 0.59);
}

.bottom-container {
  position: absolute;
  width: 1439px;
  height: 135px;
  left: 0px;
  top: 765px;

  background: #B5B0EC;
}

#start {
  position: absolute;
  width: 227.63px;
  height: 58px;
  left: 63px;
  top: 456px;

  background: rgba(0, 102, 255, 0.65);
  border-radius: 14px;

  font-family: Catamaran;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  
  text-align: center;
  display: inline-block;

  color: #ffffff;
}

#home-image {
  position: absolute;
  width: 774px;
  height: 477px;
  left: 605px;
  top: 229px;
}

body, html {
    height: 100%;
}

.home-page-body {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.home-page-top-bar{
    background-color: #f5f7fd;
    position: absolute;
    box-shadow: 0px 0px 100px rgb(204, 210, 253);
    height: 70px;
    top: 0px;
    width: 100%;
    z-index: 2;
    justify-content: space-between;
}

.home-page-logo{
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #0a1f44;
    position: absolute;
    margin: auto;
    margin-left: 20px;
    top: 20px;
    -webkit-user-select: none;
            user-select: none;
}

.home-page-main-message-font{
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    color: white;
    position: absolute;
    text-align: center;
    top: 45%;
    left: 0px;
    right: 0px;
    margin: 0px;
    -webkit-user-select: none;
            user-select: none;
}

.home-page-hero-div{
    width: 100%;
    min-height: 400px;
    height: wrap;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 50px;
    padding-top: 200px;
}

.hero-div-left{
    padding-top: 50px;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
}

.hero-div-left-primary-message{
    font-family: Avenir;
    font-style: bold;
    font-weight: 900;
    font-size: 40px;
    color: #0a1f44;
}

.hero-div-left-secondary-message{
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: Avenir;
    font-weight: 100;
    font-size: 20px;
    color: black;
    font-style: normal;
}

.hero-div-left-signup-button{
    width: 200px;
    background-color: #5b7fff;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    vertical-align: center;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.hero-div-left-signup-button-text{
    margin: 8px;
    font-family: Avenir;
    font-weight: 100;
    font-size: 20px;
    color: white;
    font-style: normal;
    text-decoration: underline;
    -webkit-text-decoration-color: #5b7fff;
            text-decoration-color: #5b7fff;
}

.hero-div-right-image{
    padding-left: 80px;
    height: 350px;
}

.home-page-scrolldown-div{
    width: 100%;
    height: wrap;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 50px;
}

.home-page-howitworks-div{
    width: 100%;
    height: wrap;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 100px;
}

.howitworks-div{
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: wrap;
    height: wrap;
    text-align:center;
    align-items: center;
}

.homepage-timeline-image{
    width: 70%;
    height: 400px;
    top: 100px;
    margin: auto;
    padding-left: 100px;
    padding-right: 100px;
}

.homepage-timeline-center{
    position: relative;
    top: 50%;
    left: 38%;
    width: 50px; 
    height: 50px;
    border-radius: 10px; 
    background-color: white;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    align-items: center;
}

.homepage-timeline-center-text{
    color: #5b7fff;
    position: relative;
    font-family: Avenir;
    font-weight: 100;
    font-size: 40px;
    font-style: bold;
    text-align: center;
}

.timeline-div-message-box{
    padding: 50px;
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    margin: auto
}

.homepage-timeline-arrow{
    position: relative;
    top: 0px;
    left: 50%;
    bottom: 100%;
    width: 15px;
    height: 100%;
    background-repeat: no-repeat, repeat-y;
    background-image: url(https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5f7b54838095fe1d5b5d0b94_Path%203.svg);
}

.bottom-div-left-signup-button{
    width: 350px;
    left: 50%;
    background-color: #5b7fff;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    vertical-align: center;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.bottom-div-left-signup-button-text{
    margin: 8px;
    font-family: Avenir;
    font-weight: 100;
    font-size: 20px;
    color: white;
    font-style: normal;
    text-decoration: underline;
    -webkit-text-decoration-color: #5b7fff;
            text-decoration-color: #5b7fff;
    line-height: 25px;
}

.half-highlight{
    background: linear-gradient(to top, #FFFF99 50%, transparent 50%);
}
